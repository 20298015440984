import React, { useContext, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import { ReactSVG } from 'react-svg';
import { TypeAnimation } from 'react-type-animation';

import TransitionContext from '../context/TransitionContext';

export default function Layers() {
  const main = useRef();
  const { completed } = useContext(TransitionContext);
  const scrollTween = useRef();
  const snapTriggers = useRef([]);
  const { contextSafe } = useGSAP(
    () => {
      if (!completed) return;
      let panels = gsap.utils.toArray('.panel'),
          scrollStarts = [0],
          snapScroll = value => value; // for converting a pixel-based scroll value to the closest panel scroll position
      
      // create a ScrollTrigger for each panel that's only concerned about figuring out when its top hits the top of the viewport. We'll use the "start" of that ScrollTrigger to figure out snapping positions.
      panels.forEach((panel, i) => {
        snapTriggers.current[i] = ScrollTrigger.create({
          trigger: panel,
          start: "top top"
        });
      });

      // once all the triggers have calculated their start/end, create the snap function that'll accept an overall progress value for the overall page, and then return the closest panel snapping spot based on the direction of scroll
      ScrollTrigger.addEventListener("refresh", () => {
        scrollStarts = snapTriggers.current.map(trigger => trigger.start); // build an Array with just the starting positions where each panel hits the top of the viewport
        snapScroll = ScrollTrigger.snapDirectional(scrollStarts); // get a function that we can feed a pixel-based scroll value to and a direction, and then it'll spit back the closest snap position (in pixels)
      });

      ScrollTrigger.observe({
        type: "wheel,touch",
        onChangeY(self) {
          if (!scrollTween.current) {
            // find the closest snapping spot based on the direction of scroll
            let scroll = snapScroll(self.scrollY() + self.deltaY, self.deltaY > 0 ? 1 : -1);
            goToSection(scrollStarts.indexOf(scroll)); // scroll to the index of the associated panel
          }
        }
      })

      ScrollTrigger.refresh();
    },
    {
      dependencies: [completed],
      scope: main,
      revertOnUpdate: true,
    }
  );

  const goToSection = contextSafe((i) => {
    console.log("scroll to", i);
    scrollTween.current = gsap.to(window, {
      scrollTo: { y: snapTriggers.current[i].start, autoKill: false },
      duration: 1,
      onComplete: () => (scrollTween.current = null),
      overwrite: true
    });
  });

  return (
    <main ref={main}>
      <div><ReactSVG src="images/Header.svg" /></div>
     
      <div><ReactSVG src="images/MainSVG2.svg" /></div>
      
      
        <div class="container content">
         <div class="row">
       <div class="col-md">
        <p>purple   am pellentesque neque varius euismod facilisis. Sed dolor eros, mollis id accumsan ut, vulputate vitae massa. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi eget luctus nisi. Phasellus ac sem id quam porttitor sollicitudin et id ex. Mauris eu ante eget augue tincidunt pellentesque eget quis nisi. Phasellus venenatis quam at libero commodo, ac sodales purus varius. Proin malesuada leo urna, nec condimentum odio ornare varius. Ut dapibus ex ac elit interdum, eu dapibus felis pellentesque. Suspendisse nec purus in ipsum congue lacinia. Sed fermentum, sapien ut finibus malesuada, dui arcu tincidunt lectus, et convallis velit sapien et mauris. Nam consectetur facilisis finibus. Cras dapibus malesuada metus dignissim vulputate. Cras eget pretium odio, nec rhoncus erat. Nam eu congue odio. Aenean sit amet rhoncus odio, aliquam pharetra est.</p>
     </div>

      <div class="col-md">
    <p>purple   am pellentesque neque varius euismod facilisis. Sed dolor eros, mollis id accumsan ut, vulputate vitae massa. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi eget luctus nisi. Phasellus ac sem id quam porttitor sollicitudin et id ex. Mauris eu ante eget augue tincidunt pellentesque eget quis nisi. Phasellus venenatis quam at libero commodo, ac sodales purus varius. Proin malesuada leo urna, nec condimentum odio ornare varius. Ut dapibus ex ac elit interdum, eu dapibus felis pellentesque. Suspendisse nec purus in ipsum congue lacinia. Sed fermentum, sapien ut finibus malesuada, dui arcu tincidunt lectus, et convallis velit sapien et mauris. Nam consectetur facilisis finibus. Cras dapibus malesuada metus dignissim vulputate. Cras eget pretium odio, nec rhoncus erat. Nam eu congue odio. Aenean sit amet rhoncus odio, aliquam pharetra est.</p>
      </div>
  </div>
    </div>
      
      <section className="panel purple"><p>purple   am pellentesque neque varius euismod facilisis. Sed dolor eros, mollis id accumsan ut, vulputate vitae massa. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi eget luctus nisi. Phasellus ac sem id quam porttitor sollicitudin et id ex. Mauris eu ante eget augue tincidunt pellentesque eget quis nisi. Phasellus venenatis quam at libero commodo, ac sodales purus varius. Proin malesuada leo urna, nec condimentum odio ornare varius. Ut dapibus ex ac elit interdum, eu dapibus felis pellentesque. Suspendisse nec purus in ipsum congue lacinia. Sed fermentum, sapien ut finibus malesuada, dui arcu tincidunt lectus, et convallis velit sapien et mauris. Nam consectetur facilisis finibus. Cras dapibus malesuada metus dignissim vulputate. Cras eget pretium odio, nec rhoncus erat. Nam eu congue odio. Aenean sit amet rhoncus odio, aliquam pharetra est.</p>
      </section>
      <section className="panel orange"><p>Aliquam pellentesque neque varius euismod facilisis. Sed dolor eros, mollis id accumsan ut, vulputate vitae massa. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi eget luctus nisi. Phasellus ac sem id quam porttitor sollicitudin et id ex. Mauris eu ante eget augue tincidunt pellentesque eget quis nisi. Phasellus venenatis quam at libero commodo, ac sodales purus varius. Proin malesuada leo urna, nec condimentum odio ornare varius. Ut dapibus ex ac elit interdum, eu dapibus felis pellentesque. Suspendisse nec purus in ipsum congue lacinia. Sed fermentum, sapien ut finibus malesuada, dui arcu tincidunt lectus, et convallis velit sapien et mauris. Nam consectetur facilisis finibus. Cras dapibus malesuada metus dignissim vulputate. Cras eget pretium odio, nec rhoncus erat. Nam eu congue odio. Aenean sit amet rhoncus odio, aliquam pharetra est.</p></section>
      <section className="panel red"><p>Red     pellentesque neque varius euismod facilisis. Sed dolor eros, mollis id accumsan ut, vulputate vitae massa. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi eget luctus nisi. Phasellus ac sem id quam porttitor sollicitudin et id ex. Mauris eu ante eget augue tincidunt pellentesque eget quis nisi. Phasellus venenatis quam at libero commodo, ac sodales purus varius. Proin malesuada leo urna, nec condimentum odio ornare varius. Ut dapibus ex ac elit interdum, eu dapibus felis pellentesque. Suspendisse nec purus in ipsum congue lacinia. Sed fermentum, sapien ut finibus malesuada, dui arcu tincidunt lectus, et convallis velit sapien et mauris. Nam consectetur facilisis finibus. Cras dapibus malesuada metus dignissim vulputate. Cras eget pretium odio, nec rhoncus erat. Nam eu congue odio. Aenean sit amet rhoncus odio, aliquam pharetra est.</p></section>
    </main>
  );
}
