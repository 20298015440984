import React from 'react';
// import { Link } from 'react-router-dom'; Will be used if links are added

export default function Header() {
  return (
    <header className="header">
      
      {/* This is the nav below */}
      {/* <nav>
        <ul>
          <li>
            <Link to="/">Boxes</Link>
          </li>
          <li>
            <Link to="/scroll">ScrollTrigger</Link>
          </li>
          <li>
            <Link to="/layers">Layers Section</Link>
          </li>
        </ul>
      </nav> */}
      
    </header>
  );
}
